<template>
    <el-main ref="main">
        <el-tabs v-model="step" @tab-click="tabSwich">
            <el-tab-pane label="分销设置" name="1">
                <Set @updateGradeList="updateGradeList" @tabSwich="tabSwich" :gradeList="gradeList" :info="set"></Set>
            </el-tab-pane>
            <el-tab-pane label="等级设置" name="2">
                <grade v-if="setInfo.id" ref="grade" @tabSwich="tabSwich" :setInfo="set"
                    @getGradeList="(val, ls_type) => ((gradeList = val), (setInfo.ls_type = ls_type))"
                    :ls_type="setInfo.ls_type"></grade>
            </el-tab-pane>
            <el-tab-pane label="佣金设置" name="3">
                <centCommission :formInfo="commissionInfo"></centCommission>
            </el-tab-pane>
            <!-- <el-tab-pane label="字段设置" name="4">
        <fieldSet v-if="fields" :fieldss="fields"></fieldSet>
      </el-tab-pane> -->
            <el-tab-pane label="分享海报" name="5">
                <Share></Share>
            </el-tab-pane>
            <el-tab-pane label="分销规则" name="6">
                <p>修改分销前端加入享受权益字段</p>
                <el-form label-width="120px" style="width: 700px; margin-top: 20px">
                    <el-form-item label="标题修改：">
                        <el-input v-model="rule.title"></el-input>
                    </el-form-item>
                    <el-form-item label="分销权益：">
                        <el-input v-model="rule.content" type="textarea" :rows="6"></el-input>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
            <el-tab-pane label="服务设置" name="7">
                <div class="serviceSet">
                    <div>
                        <span>服务设置</span>
                        <el-switch></el-switch>
                    </div>
                    <div>
                        <p>
                            注意：此服务设置只适用于一级分销，如果为二三级分销，请修改
                            <el-link type="primary">分销设置-分销层次</el-link>
                            后，在开启服务设置
                        </p>
                        <p>服务设置开启后，分销员佣金除了和分销等级有关，还与服务结算折扣有关</p>
                    </div>
                </div>
                <div class="service-set-list">
                    <div class="header">
                        <div>服务结算折扣</div>
                        <div>分销员等级</div>
                        <div>佣金比列设置</div>
                        <div>操作</div>
                    </div>
                    <div class="item" v-for="(item, index) in serviceSetList" :key="index">
                        <div v-if="item.is_edit">
                            <p>
                                <el-input v-model="item.discount"></el-input>
                                %
                            </p>
                            <p>{{ item.pre_discount }}%</p>
                        </div>
                        <div v-else>
                            <span v-if="item.id">（{{ item.pre_discount }}%，{{ item.discount }}%）</span>
                            <span v-else>{{ item.discount }}%</span>
                        </div>
                        <div>
                            <p v-for="(level, y) in item.values" :key="y">{{ level.name }}</p>
                        </div>
                        <div>
                            <p v-for="(level, y) in item.values" :key="y">
                                可拿佣金比例
                                <el-input v-if="item.is_edit" v-model="level.commission_rate_stair"></el-input>
                                <span v-else>{{ level.commission_rate_stair }}</span>
                                %
                            </p>
                        </div>
                        <div>
                            <p v-if="item.id == 0">
                                可在
                                <el-button type="text">等级设置</el-button>
                                修改
                            </p>
                            <el-button v-if="item.id || item.is_add" type="text" @click="editService(item, index)">{{
                                item.is_edit ? '保存' : '编辑' }}</el-button>
                            <el-button v-if="item.id || item.is_add" type="text" @click="delService(item, index)">{{ item.id
                                && item.is_edit ? '取消' : '删除' }}</el-button>
                        </div>
                    </div>
                </div>
                <el-button type="primary" size="small" icon="el-icon-plus" @click="addServiceDiscount">添加折扣结算</el-button>
            </el-tab-pane>
        </el-tabs>
        <Preservation v-if="step == '6'" @preservation="saveRule"></Preservation>
    </el-main>
</template>

<script>
import grade from './components/grade';
import centCommission from './components/centCommission';
import Set from './components/set';
// import fieldSet from './components/fieldSet';
import Preservation from '@/components/preservation';
import Share from './components/share';
export default {
    components: {
        grade,
        centCommission,
        Set,
        // fieldSet,
        Preservation,
        Share,
    },
    data () {
        return {
            step: '1',
            form: {},
            setInfo: {
                ls_type: 1,
            },
            serviceSetList: [],
            gradeList: [],
            commissionInfo: {
                type: 0,
                is_level_dividend: 0,
                level_id: '',
                level_dividend_type: 1,
                level_dividend_rate: '',
            },
            set: {
                tier: 0,
                in_purchasing: 0,
                term: 1,
                value: '',
                buy: '',
                money: '',
                pay_amount: '',
                is_pay: 0,
                goods_name: '',
                is_task: 0,
                is_electronic_agreement: 0,
                is_binding: 0,
                task_value: {
                    value: '',
                    date: '',
                    is_clear: 1,
                },
                opRequired: [
                    {
                        name: '用户姓名',
                        is_select: '1',
                        type: 1,
                    },
                    {
                        name: '用户性别',
                        is_select: '1',
                        type: 1,
                    },
                    {
                        name: '用户职业',
                        is_select: '1',
                        type: 1,
                    },
                    {
                        name: '用户生日',
                        is_select: '1',
                        type: 1,
                    },
                ],
                optional: [
                    {
                        name: '用户姓名',
                        is_select: '0',
                        type: 1,
                    },
                    {
                        name: '用户性别',
                        is_select: '0',
                        type: 1,
                    },
                    {
                        name: '用户职业',
                        is_select: '0',
                        type: 1,
                    },
                    {
                        name: '用户生日',
                        is_select: '0',
                        type: 1,
                    },
                ],
            },
            rule: {
                title: '—加入推广享受权益—',
                content: '1、点击申请，申请成为推广员。\n2、成功推广员后，自己购买商品可获得返利。\n3、好物分享更多人获得更多权益。',
            },
            fields: null,
        };
    },
    created () {
        if (this.$route.query.step) this.step = this.$route.query.step;
        this.getSetInfo();
        this.getServiceSet();
    },
    methods: {
        tabSwich (val) {
            if (typeof val == 'string') {
                this.step = val;
            }
        },
        updateGradeList () {
            if (this.gradeList.length) {
                this.$refs.grade.getList();
            } else {
                this.getSetInfo().then(() => {
                    this.$refs.grade.getList();
                });
            }
        },
        getSetInfo () {
            return new Promise(resolve => {
                this.$axios.post('/Beauty' + this.$api.distribution.setInfo).then(res => {
                    if (res.code == 0) {
                        if (res.result) {
                            this.setInfo = res.result;
                            this.commissionInfo = {
                                type: this.setInfo.type,
                                is_level_dividend: this.setInfo.is_level_dividend,
                                level_id: this.setInfo.level_id || '',
                                level_dividend_type: this.setInfo.level_dividend_type,
                                level_dividend_rate: this.setInfo.level_dividend_rate,
                            };
                            this.set.tier = this.setInfo.tier;
                            this.set.in_purchasing = this.setInfo.in_purchasing;
                            this.set.term = this.setInfo.term;
                            this.set.value = this.setInfo.value;
                            this.set.is_pay = this.setInfo.is_pay;
                            this.set.pay_amount = this.setInfo.pay_amount;
                            this.set.goods_name = this.setInfo.goods_name;
                            this.set.is_electronic_agreement = this.setInfo.is_electronic_agreement;
                            this.set.is_task = this.setInfo.is_task;
                            this.set.is_binding = this.setInfo.is_binding;
                            if (this.setInfo.task_value) {
                                this.setInfo.task_value = JSON.parse(this.setInfo.task_value);
                                this.set.task_value.value = this.setInfo.task_value.value;
                                this.set.task_value.date = Number(this.setInfo.task_value.date);
                                this.set.task_value.is_clear = Number(this.setInfo.task_value.is_clear);
                            }
                            if (this.setInfo.term == 1 || this.setInfo.term == 2) {
                                this.set.opRequired = JSON.parse(JSON.stringify(this.setInfo.values.opRequired));
                                this.set.optional = JSON.parse(JSON.stringify(this.setInfo.values.optional));
                                this.set.is_pay = Number(this.setInfo.values.is_pay);
                                this.set.pay_amount = this.setInfo.values.pay_amount;
                            } else if (this.setInfo.term == 3) {
                                this.set.money = this.setInfo.values;
                            } else {
                                this.set.buy = this.setInfo.values;
                            }
                            resolve();
                        } else {
                            this.setInfo.id = 1;
                        }
                    }
                });
                this.$axios.post('/Beauty' + this.$api.distribution.setInfo2).then(res => {
                    if (res.code == 0) {
                        this.rule = res.result.rule;
                        this.fields = res.result.fields;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            });
        },
        saveRule () {
            this.$axios
                .post('/Beauty' + this.$api.distribution.editRule, {
                    title: this.rule.title,
                    content: this.rule.content,
                })
                .then(res => {
                    if (res.code == 0) {
                        this.$message.success('保存成功');
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        getServiceSet () {
            this.$axios.post(this.$api.distribution.serviceSetList).then(res => {
                if (res.code == 0) {
                    let list = res.result;
                    for (let i in list) {
                        list[i].is_edit = !1;
                        if (list[i].id) {
                            list[i].pre_discount = list[i - 1].discount;
                        }
                    }
                    this.serviceSetList = list;
                }
            });
        },
        addServiceDiscount () {
            let values = [];
            if (this.serviceSetList[this.serviceSetList.length - 1].is_add) return this.$message.warning("请填写完当前服务设置");
            this.gradeList.map(item => {
                values.push({
                    name: item.name,
                    level_id: item.id,
                    commission_rate_stair: '',
                });
            });
            this.serviceSetList.push({
                is_add: !0,
                pre_discount: this.serviceSetList[this.serviceSetList.length - 1].discount,
                discount: '',
                is_edit: !0,
                values: values,
            });
        },
        editService (row) {
            if (row.is_edit) {
                if (!row.discount) return this.$message.warning("请填写服务结算折扣");
                for (let i in row.values) {
                    if (!row.values[i].commission_rate_stair) return this.$message.warning("请填写佣金比例")
                }
                if (row.discount > row.pre_discount) return this.$message.warning(`服务结算折扣不能大于${row.pre_discount}%`);
                let data = {
                    discount: row.discount,
                    value: row.values,
                };
                if (row.id) data.id = row.id;
                this.$axios.post(row.is_add ? this.$api.distribution.serviceSetAdd : this.$api.distribution.serviceSetEdit, data).then(res => {
                    if (res.code == 0) {
                        this.getServiceSet();
                        this.$message.success(row.is_add ? '添加成功' : '编辑成功');
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            } else {
                this.serviceSetList.map(item => item.is_edit = !1);
                row.is_edit = !0;
            }
        },
        delService (row, index) {
            if (row.is_add) {
                this.serviceSetList.splice(index, 1);
                return;
            }
            if (row.is_edit) {
                row.is_edit = !1;
            } else {
                this.$axios
                    .post(this.$api.distribution.serviceSetDel, {
                        id: row.id,
                    })
                    .then(res => {
                        if (res.code == 0) {
                            this.serviceSetList.splice(index, 1);
                            this.$message.success('删除成功');
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
            }
        },
    },
};
</script>

<style lang="less" scoped>
.el-main {
    background: #fff;
    height: 100%;
    font-size: 14px;
    padding-bottom: 80px;

    .serviceSet {
        display: flex;

        div {
            margin-right: 20px;

            span {
                margin-right: 15px;
            }

            p:nth-child(1) {
                margin-bottom: 15px;
                color: rgb(243, 44, 44);
            }

            p:nth-child(2) {
                color: rgb(153, 153, 153);
            }
        }
    }

    .service-set-list {
        margin: 20px 0;
        border: 1px solid #ebeef5;

        .header {
            height: 50px;
            background: #f8f9fa;
            display: flex;

            div {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            div:nth-child(1) {
                width: 200px;
            }

            div:nth-child(2) {
                flex: 1;
            }

            div:nth-child(3) {
                flex: 1;
            }

            div:nth-child(4) {
                width: 200px;
            }
        }

        .item {
            display: flex;
            border-bottom: 1px solid #ebeef5;

            &:last-child {
                border: 0;
            }

            div:nth-child(1) {
                width: 200px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-right: 1px solid #ebeef5;
                flex-direction: column;

                p {
                    display: flex;
                    align-items: center;
                    margin-bottom: 5px;
                }

                P:nth-child(2) {
                    font-size: 12px;
                }

                .el-input {
                    margin: 0 10px;
                    width: 120px;
                }
            }

            div:nth-child(2) {
                flex: 1;

                p {
                    width: 100%;
                    border-bottom: 1px solid #ebeef5;
                    height: 80px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &:last-child {
                        border: 0;
                    }
                }
            }

            div:nth-child(3) {
                height: 60px;
                flex: 1;

                p {
                    width: 100%;
                    border-bottom: 1px solid #ebeef5;
                    height: 80px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &:last-child {
                        border: 0;
                    }
                }

                .el-input {
                    margin: 0 10px;
                }
            }

            div:nth-child(4) {
                border-left: 1px solid #ebeef5;
                width: 200px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}
</style>
